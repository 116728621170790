/*default version*/

@font-face {
    font-family: 'MontserratARM';
    src: url(./fonts/MONTSERRATARM-BLACK.OTF) format('opentype');
    font-weight: normal;
    color: black;
}

@font-face {
    font-family: 'MontserratARM';
    src: url(./fonts/MONTSERRATARM-BOLD.OTF) format('opentype');
    font-weight: bolder;
}

@font-face {
    font-family: 'MontserratARM';
    src: url(./fonts/MONTSERRATARM-EXTRABOLD.OTF) format('opentype');
    font-weight: bold;
}

@font-face {
    font-family: 'MontserratARM';
    src: url(./fonts/MONTSERRATARM-EXTRALIGHT.OTF) format('opentype');
    font-weight: 100;
}

@font-face {
    font-family: 'MontserratARM';
    src: url(./fonts/MONTSERRATARM-LIGHT.OTF) format('opentype');
    font-weight: 300;
}

@font-face {
    font-family: 'MontserratARM';
    src: url(./fonts/MONTSERRATARM-MEDIUM.OTF) format('opentype');
    font-weight: 500;
}

@font-face {
    font-family: 'MontserratARM';
    src: url(./fonts/MONTSERRATARM-REGULAR.OTF) format('opentype');
    font-weight: 400;
}

@font-face {
    font-family: 'MontserratARM';
    src: url(./fonts/MONTSERRATARM-SEMIBOLD.OTF) format('opentype');
    font-weight: 600;
}

@font-face {
    font-family: 'MontserratARM';
    src: url(./fonts/MONTSERRATARM-THIN.OTF) format('opentype');
    font-weight: 200;
}


/****** MAIN *******************/

.app {
    font: normal;
}

.app footer {
    min-height: 150px;
    background-image: url(./images/footer.png);
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    margin-top: 50px;
}
.margin20 {
    margin-left: 25px;
    margin-top: 20px;
}
footer .container {
    min-height: 150px;
}

footer .container table {
    width: 100%
}

footer .container table td:nth-child(1) {
    padding: 54px 0 0 0;
    color: #fff;
}

footer .container table td:nth-child(2) {
    padding: 54px 0 0 0;
    font-size: 10px;
    max-width: 500px;
    color: #fff;
}

footer .container table td:nth-child(3) {
    text-align: right;
    padding: 42px 0 0 0;
    width: 130px;
}

footer .container table td:nth-child(3) img {
    width: 50%;
    min-width: 240px;
}

@media only screen and (max-width: 768px) {
    footer .container {
        max-width: 90%;
        padding: 0;
    }
}


@media only screen and (max-width: 570px) {
    footer .container table td:nth-child(1) div {
        font-size: 12px;
        max-width: 75%;
    }
    footer .container table td:nth-child(3) img {
        min-width: 185px;
        width: 185px;
    }

    footer .container table td:nth-child(1) {
        padding-top: 70px;
    }
}


@media only screen and (max-width: 768px) {
    footer .container table td {
        display: block;
    }
    footer .container table td:nth-child(1) {
        padding-top: 90px;
        text-align: center;
    }

    footer .container table td:nth-child(1) div {
        max-width: 100%;
    }

    footer .container table td:nth-child(2) {
        padding: 16px 0 0 0 ;
        text-align: center;
        max-width: 100%;
    }

    footer .container table td:nth-child(3) {
        text-align: center;
        padding-top: 20px;
        padding-bottom: 10px;
        width: 100%;
    }
}

.stepperContainer {
    width: auto;
    display: inline-flex;
    background: #d5d5d5;
    padding: 7px;
    border-radius: 11px;
}

.stepperContainer .svgIcon-root-90 {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 24px;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    -moz-user-select: none;
}

.stepperContainer .active {
    color: #36c265;
    padding: 0 5px;
}

.stepperContainer .passive {
    color: #8a8a8a;
    padding: 0 5px;
}

.stepperContainer .completed {
    color: #36c265;
    padding: 0 5px;
}

.stepperContainer .stepIcon-text-86 {
    fill: #fff;
    font-size: 0.75rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

/* SMALL SCREENS 1360 px AND LESS */

@media only screen and (max-width: 1360px) {
    .pageSection {
        width: 100%;
        min-height: 450px;
        margin: auto;
    }
    /****** BUSINESS PART **********/
    .business {
        text-align: left;
        min-height: 520px;
    }
    .business div.info {
        width: 85%;
        margin: auto;
        display: block;
    }
    .business div.info h1 {
        color: #36c265;
        font-size: 40px;
        line-height: 100%;
        font-weight: bold;
    }
    .business div.info div {
        padding: 10px 0 15px 0;
        font-size: 17px;
        font-weight: 300;
        line-height: 21px;
    }
    .business.background {
        background-image: url(./images/business.jpg);
        background-repeat: no-repeat;
        background-position: right;
        /* background-size: contain; */
        background-size: 580px;
    }
    .business div.compLogo {
        text-align: center;
        padding: 33px 0 40px;
    }
    .business img.compLogo {
        width: 350px;
        padding: 20px;
    }
    /****** WE PROVIDE **************/
    .weProvide {
        text-align: right;
        min-height: 510px;
    }
    .weProvide div.info {
        width: 85%;
        margin: 52px auto;
        display: block;
    }
    .weProvide div.info h1 {
        color: #36c265;
        font-size: 40px;
        line-height: 100%;
        font-weight: bold;
    }
    .weProvide div.info div {
        padding: 10px 0px;
        font-size: 17px;
        font-weight: 300;
        line-height: 21px;
    }
    .weProvide div.info span.amount {
        color: #36c265;
        font-size: 32px;
        font-weight: bold;
    }
    .weProvide div.info span.bold {
        font-weight: bold;
    }
    .weProvide.background {
        background-image: url(./images/we-provide.png);
        background-repeat: no-repeat;
        background-position: left;
        /* background-size: 650px; */
        background-size: contain;
    }
    /****** FORM FILLING ***********/
    .formFilling {
        text-align: left;
        min-height: 900px;
    }
    .formFilling div.info {
        width: 75%;
        margin: 28px;
        display: block;
        padding: 15px 0;
    }
    .formFilling div.info h1 {
        color: #000000;
        font-size: 40px;
        line-height: 100%;
        font-weight: bold;
    }
    /*.formFilling.background0 {*/
    /*    background-image: url(./images/form-filling-0.png);*/
    /*    background-repeat: no-repeat;*/
    /*    background-position: right top;*/
    /*    background-size: 500px;*/
    /*    !* background-size: contain; *!*/
    /*}*/
    /*.formFilling.background1 {*/
    /*    background-image: url(./images/form-filling-1.png);*/
    /*    background-repeat: no-repeat;*/
    /*    background-position: right top;*/
    /*    background-size: 500px;*/
    /*    !* background-size: contain; *!*/
    /*}*/
    .formFilling.background2 {
        background-image: url(./images/form-filling-2.png);
        background-repeat: no-repeat;
        background-position: right top;
        background-size: 500px;
        /* background-size: contain; */
    }
    .link,
    .link:hover {
        color: #36c265;
        font-size: 12px;
        font-weight: 600;
        text-decoration: underline;
    }
}

@media only screen and (max-width: 768px) {
    .business div.compLogo {
        padding: 30px 15px;
    }
    .business img.compLogo {
        width: 285px;
        padding: 0;
    }
    .business {
        min-height: auto;
    }
    .business.background {
        background-position: top right;
        -webkit-background-size: 360px;
        background-size: 360px;
    }
    .business div.info {
        width: 90%;
    }
    .business div.info h1 {
        font-size: 32px;
        margin-bottom: 20px;
    }
    .business div.info div {
        padding: 5px 0;
        font-size: 15px;
        font-weight: 400;
    }
    .business div.info div:last-child {
        padding-top: 20px;
    }
}


@media only screen and (max-width: 570px) {
    .business div.compLogo {
        padding: 20px 5%;
        text-align: left;
    }
    .business img.compLogo {
        width: 185px;
    }
    .business.background {
        -webkit-background-size: 250px;
        background-size: 250px;
    }
    .business div.info h1 {
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 15px;
    }
    .business div.info div {
        padding: 5px 0;
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
        max-width: 280px;
    }
    .business div.info div:last-child {
        padding-top: 15px;
    }
}

@media only screen and (max-width: 480px) {

}

@media only screen and (max-width: 460px) {

    .business.background {
        -webkit-background-size: 140px;
        background-size: 140px;
    }

    .business div.info div {
        max-width: 100%;
    }
}

/* SMALLER SCREENS 1684px AND LESS */

@media only screen and (min-width: 1361px) {
    .pageSection {
        width: 100%;
        min-height: 450px;
        margin: auto;
    }
    /****** BUSINESS PART **********/
    .business {
        text-align: left;
        min-height: 610px;
    }
    .business div.info {
        width: 85%;
        margin: auto;
        display: block;
    }
    .business div.info h1 {
        color: #36c265;
        font-size: 40px;
        line-height: 100%;
        font-weight: bold;
    }
    .business div.info div {
        padding: 10px 0 15px 0;
        font-size: 18px;
        font-weight: 300;
        line-height: 21px;
    }
    .business.background {
        background-image: url(./images/business.jpg);
        background-repeat: no-repeat;
        background-position: right;
        /* background-size: contain; */
        background-size: 680px;
    }
    .business div.compLogo {
        text-align: center;
        padding: 66px 0 55px;
    }
    .business img.compLogo {
        width: 350px;
        padding: 20px;
    }
    /****** WE PROVIDE **************/
    .weProvide {
        text-align: right;
        min-height: 610px;
    }
    .weProvide div.info {
        width: 75%;
        margin: 52px auto;
        display: block;
    }
    .weProvide div.info h1 {
        color: #36c265;
        font-size: 40px;
        line-height: 100%;
        font-weight: bold;
    }
    .weProvide div.info div {
        padding: 20px 0px;
        font-size: 18px;
        font-weight: 300;
        line-height: 21px;
    }
    .weProvide div.info span.amount {
        color: #36c265;
        font-size: 32px;
        font-weight: bold;
    }
    .weProvide div.info span.bold {
        font-weight: bold;
    }
    .weProvide.background {
        background-image: url(./images/we-provide.png);
        background-repeat: no-repeat;
        background-position: left;
        /* background-size: 650px; */
        background-size: contain;
    }
    /****** FORM FILLING ***********/
    .formFilling {
        text-align: left;
        min-height: 900px;
    }
    .formFilling div.info {
        width: 75%;
        margin: 28px;
        display: block;
        padding: 15px 0;
    }
    .formFilling div.info h1 {
        color: #000000;
        font-size: 40px;
        line-height: 100%;
        font-weight: bold;
    }
    /*.formFilling.background0 {*/
    /*    background-image: url(./images/form-filling-0.png);*/
    /*    background-repeat: no-repeat;*/
    /*    background-position: right top;*/
    /*    background-size: 600px;*/
    /*    !* background-size: contain; *!*/
    /*}*/
    /*.formFilling.background1 {*/
    /*    background-image: url(./images/form-filling-1.png);*/
    /*    background-repeat: no-repeat;*/
    /*    background-position: right top;*/
    /*    background-size: 600px;*/
    /*    !* background-size: contain; *!*/
    /*}*/
    .formFilling.background2 {
        background-image: url(./images/form-filling-2.png);
        background-repeat: no-repeat;
        background-position: right top;
        background-size: 600px;
        /* background-size: contain; */
    }
    .link,
    .link:hover {
        color: #36c265;
        font-size: 12px;
        font-weight: 600;
        text-decoration: underline;
    }
}


/* BIGGER SCREENS 1685px AND MORE */

@media only screen and (min-width: 1685px) {
    .pageSection {
        width: 100%;
        min-height: 450px;
        margin: auto;
    }
    /****** BUSINESS PART **********/
    .business {
        text-align: left;
        min-height: 610px;
    }
    .business div.info {
        width: 65%;
        margin: auto;
        display: block;
    }
    .business div.info h1 {
        color: #36c265;
        font-size: 40px;
        line-height: 100%;
        font-weight: bold;
    }
    .business div.info div {
        padding: 10px 0 15px 0;
        font-size: 18px;
        font-weight: 300;
        line-height: 21px;
    }
    .business.background {
        background-image: url(./images/business.jpg);
        background-repeat: no-repeat;
        background-position: right;
        /* background-size: contain; */
        background-size: 680px;
    }
    .business div.compLogo {
        text-align: center;
        padding: 66px 0 55px;
    }
    .business img.compLogo {
        width: 350px;
        padding: 20px;
    }
    /****** WE PROVIDE **************/
    .weProvide {
        text-align: right;
        min-height: 610px;
    }
    .weProvide div.info {
        width: 75%;
        margin: 52px auto;
        display: block;
    }
    .weProvide div.info h1 {
        color: #36c265;
        font-size: 40px;
        line-height: 100%;
        font-weight: bold;
    }
    .weProvide div.info div {
        padding: 20px 0px;
        font-size: 18px;
        font-weight: 300;
        line-height: 21px;
    }
    .weProvide div.info span.amount {
        color: #36c265;
        font-size: 32px;
        font-weight: bold;
    }
    .weProvide div.info span.bold {
        font-weight: bold;
    }
    .weProvide.background {
        background-image: url(./images/we-provide.png);
        background-repeat: no-repeat;
        background-position: left;
        /* background-size: 650px; */
        background-size: contain;
    }
    /****** FORM FILLING ***********/
    .formFilling {
        text-align: left;
        min-height: 900px;
    }
    .formFilling div.info {
        width: 75%;
        margin: auto;
        display: block;
        padding: 15px 0;
    }
    .formFilling div.info h1 {
        color: #000000;
        font-size: 40px;
        line-height: 100%;
        font-weight: bold;
    }
    /*.formFilling.background0 {*/
    /*    background-image: url(./images/form-filling-0.png);*/
    /*    background-repeat: no-repeat;*/
    /*    background-position: right top;*/
    /*    background-size: 600px;*/
    /*    !* background-size: contain; *!*/
    /*}*/
    /*.formFilling.background1 {*/
    /*    background-image: url(./images/form-filling-1.png);*/
    /*    background-repeat: no-repeat;*/
    /*    background-position: right top;*/
    /*    background-size: 600px;*/
    /*    !* background-size: contain; *!*/
    /*}*/
    .formFilling.background2 {
        background-image: url(./images/form-filling-2.png);
        background-repeat: no-repeat;
        background-position: right top;
        background-size: 600px;
        /* background-size: contain; */
    }
    .link,
    .link:hover {
        color: #36c265;
        font-size: 12px;
        font-weight: 600;
        text-decoration: underline;
    }
}

@media only screen and (max-width: 768px) {
    .formFilling {
        min-height: auto;
    }

    .formFilling.background0 {
        -webkit-background-size: 150px;
        background-size: 150px;

    }

    .formFilling.background1 {
        -webkit-background-size: 150px;
        background-size: 150px;
    }

    .formFilling.background2 {
        -webkit-background-size: 150px;
        background-size: 150px;
    }

    .formFilling div.info {
        min-height: 310px;
        width: 90%;
        margin: auto;
        padding: 15px;
        background: rgba(255, 255, 255, .35);
        -webkit-box-shadow: 0 0 0 1px rgba(235,235,235,1);
        -moz-box-shadow: 0 0 0 1px rgba(235,235,235,1);
        box-shadow: 0 0 0 1px rgba(235,235,235,1);
    }

    .formFilling div.info h1 {
        font-size: 32px;
    }

    .weProvide {
        min-height: auto;
    }

    .weProvide div.info {
        width: 90%;
    }

    .weProvide div.info h1 {
        font-size: 32px;
    }

    .weProvide div.info div {
        font-size: 15px;
        font-weight: 400;
        max-width: 70%;
        margin-left: 30%;
    }

    .weProvide div.info span.amount {
        font-size: 26px;
    }
}


@media only screen and (max-width: 570px) {


    .formFilling div.info h1 {
        font-size: 32px;
    }

    .weProvide div.info h1 {
        font-size: 22px;
        line-height: 26px;
    }

    .weProvide div.info h1 br {
        display: none;
    }

    .weProvide div.info div {
        font-size: 13px;
        line-height: 21px;
    }

    .weProvide div.info span.amount {
        font-size: 20px;
    }
}



@media only screen and (max-width: 480px) {
    .weProvide.background {
        -webkit-background-size: 200px;
        background-size: 200px;
    }


    .formFilling div.info h1 {
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 15px;
    }

    .thin {
        font-size: 16px;
    }
}


/*******************************/


/**** FORM FILLING ADDITION ****/

.filesBlock {
    border: 1px gray dashed;
    border-radius: 5px;
    padding: 20px 0 5px 0;
    min-width: 366px;
    width: 380px;
}

.infoBlock {
    min-width: 350px;
}

.infoBlock label {
    color: #444;
    font-size: 14px;
    font-weight: 300;
    padding-left: 15px;
}

.infoBlock hr {
    background-color: #36c265;
    width: 180px;
}

.infoBlock input {
    font-size: 16px;
    font-weight: 300;
}

.optionalBox {
    border: 1px #36c265 dashed;
    border-radius: 5px;
    margin: 0 -8px 20px -8px;
    padding: 20px 7px;
    max-width: 825px;
}


/**** FORM FILLING FINAL *******/

.formFinal {
    text-align: center;
    padding: 175px 0;
}

.formFinal div.info h1 {
    color: #36c265;
    font-size: 40px;
    line-height: 100%;
    font-weight: bold;
}

.formFinal div.info div {
    padding: 20px 0px;
    font-size: 18px;
    font-weight: 300;
    line-height: 21px;
}

.formFinal div.info {
    width: 100%;
}

.messagesContainer {
    padding: 30px;
    color: #444;
    text-align: left;
}

.buttonsContainer {
    padding: 16px;
}

.effective-rate {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 9000;
	background-color: rgba(0,0,0,0.8);
	}
.effective-rate-content {
	width: 100%;
	max-width: 880px;
	margin: 0 auto 0 auto;
	position: relative;
	color: #fff;
	padding: 12px 0;
	text-align: center;
	font-size: 12px;
	}
.effective-rate-close {
	display: inline-block;
	width: 190px;
	height: 34px;
	border: 1px solid #ffffff;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	color: #ffffff;
	font-size: 12px;
	line-height: 34px;
	font-weight: 400;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	outline: none;
	transition: 0.6s;
	margin-top: 12px;
	cursor: pointer;
	}

/********* BUTTONS ************/

.preNext {
    color: inherit;
    border: 0;
    margin: 0;
    cursor: pointer;
    display: inline-flex;
    outline: none;
    padding: 0;
    position: relative;
    align-items: center;
    border-radius: 0;
    vertical-align: middle;
    justify-content: center;
    -moz-appearance: none;
    text-decoration: none;
    background-color: transparent;
    -moz-user-select: none;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.preNextFub {
    color: rgba(0, 0, 0, 0.87);
    width: 56px;
    height: 56px;
    padding: 0;
    font-size: 0.875rem;
    min-width: 0;
    box-sizing: border-box;
    min-height: 36px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    font-weight: 500;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    border-radius: 50%;
    text-transform: uppercase;
    background-color: #e0e0e0;
}

.nextButton {
    width: auto;
    color: white;
    height: 40px;
    padding: 0 30px;
    min-width: 50px;
    font-size: 15px;
    border-radius: 3px;
    background-color: #36c265;
}

.nextButton:hover {
    background-color: #47d376;
}

.nextButton .label {
    width: 100%;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
}

.nextButton .touchRipple {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 0;
    position: absolute;
    overflow: hidden;
    border-radius: inherit;
    pointer-events: none;
}

.nextButton .arrow-next {
    padding-left: 15px;
}

.nextButton .arrow-up,
.arrow-down {
    padding-left: 15px;
}

.arrow-back {
    padding-right: 15px;
}

.errorButton {
    width: auto;
    color: white;
    height: 40px;
    padding: 0 30px;
    min-width: 50px;
    font-size: 15px;
    border-radius: 8px;
    background-color: #e64100;
}

.errorButton:hover {
    background-color: #e64100;
}

.errorButton .label {
    width: 100%;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
}

.errorButton .touchRipple {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 0;
    position: absolute;
    overflow: hidden;
    border-radius: inherit;
    pointer-events: none;
}

.errorButton .arrow-next {
    padding-left: 15px;
}

.errorButton .arrow-up,
.arrow-down {
    padding-left: 15px;
}

.messageButton {
    width: auto;
    color: white;
    height: 40px;
    padding: 0 30px;
    min-width: 50px;
    font-size: 15px;
    border-radius: 8px;
    background-color: #47d376;
}

.messageButton:hover {
    color: white;
    background-color: #47d376;
}

.messageButton .label {
    width: 100%;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
}

.messageButton:disabled {
    opacity: .65;
    cursor: default;
}


/*******************************/

.btn-file {
    position: relative;
    overflow: hidden;
}

.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}


/**** INPUT FILE ****/

.js .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile+label {
    width: 85%;
    min-width: 315px;
    /* max-width: 80%; */
    font-size: 12px;
    font-weight: 600;
    /* text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 0.625rem 1.25rem; */
    cursor: pointer;
}

select.form-control {
    font-weight: 300;
}


/* .no-js .inputfile + label {
    display: none;
} */

.inputfile:focus+label,
.inputfile.has-focus+label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}

.inputfile+label * {
    /* pointer-events: none; */
    /* in case of FastClick lib use */
}

.inputfile+label svg {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    margin-top: -0.25em;
    /* 4px */
    margin-right: 0.25em;
    /* 4px */
}


/* style 7 Main style*/

.inputfile-7+label {
    color: #36c265;
}

.inputfile-7+label {
    border: 1px solid #02b63e;
    background-color: #ffffff;
    padding: 0;
    margin: 0px 27px;
}

.inputfile-7:focus+label,
.inputfile-7.has-focus+label,
.inputfile-7+label:hover {
    border-color: #0b912c;
}

.inputfile-7+label span,
.inputfile-7+label strong {
    /* padding: 0.625rem 1.25rem; */
    /* 10px 20px */
    padding: 4px 6px 6px 8px;
    margin: 9px;
}

.inputfile-7+label span {
    width: 79%;
    /* width: 252px; */
    /* min-height: 2em; */
    display: inline-block;
    text-overflow: ellipsis;
    /* white-space: nowrap; */
    overflow: hidden;
    vertical-align: top;
}

.inputfile-7+label strong {
    height: 100%;
    color: #ffffff;
    background-color: #1bbe51;
    display: inline-block;
    /**/
    /* width: 100px;
    height: 100px; */
    border-radius: 50%;
    background-color: #12b63b;
    /*display: block;
     padding: 20px;
    margin: 0 auto 10px; */
}

.inputfile-7:focus+label strong,
.inputfile-7.has-focus+label strong,
.inputfile-7+label:hover strong {
    background-color: #058610;
}

.inputfile-7.is-invalid+label span {
    color: red;
}
.inputfile-7.is-invalid+label {
    border: 1px solid red;
}
.inputfile-7.is-invalid+label strong {
    background-color: red;
}

@media screen and (max-width: 768px ) {
    /*.inputfile-7+label strong {*/
    /*    display: block;*/
    /*}*/

    .thin {
        font-weight: 400;
    }

    .col-sm-3 {
        width: 51%  !important;
        max-width: 51%  !important;
        flex: 0 0 51%  !important;
    }
    .col-sm-3 .form-group{
        margin-bottom: .5rem !important;
    }
}

@media screen and (max-width: 570px) {
    .blockPage {
    }
}

@media screen and (max-width: 480px ) {

    .col-sm-3 {
        width: 100%  !important;
        max-width: 100%  !important;
        flex: 0 0 100%  !important;

    }
}

.infoBlock .form-control:invalid,
.infoBlock .form-control:valid {
    background-image: none;
}

div.uploaded-info {
    font-size: 11px;
    font-weight: 400;
    text-align: right;
    width: 85%;
    margin: 0 25px;
    min-height: 25px;
}

/* MODAL DIALOG*/
.modalHeaderContainer {
    padding: 14px;
    border-top: 1px solid #e9e9e9;
    background-color: #f3f3f3;
    border-radius: 15px 15px 0 0;
    text-align: left;
    color: gray;
}
.modalHeaderContainer .chromeLogo {
    width: 208px;
    padding: 0px 20px;
}

.modalMessagesContainer {
    text-align: left;
    padding: 18px;
    overflow-y: scroll;
    height: 400px;
}

.modalMessagesContainer .innerContainer {
    text-align: left
}

.modalMessagesContainer .agTitle {
    padding: 10px 0;
    font-size: 18px;
    font-weight: bolder;
    font-style: italic;
}
.modalMessagesContainer .agText {
    text-align: justify;
}

.modalMessagesContainer .agDescTitle {
    padding: 10px 0;
    font-size: 18px;
    font-weight: bolder;
}

.modalMessagesContainer .agDesc {
    font-style: italic;
    text-align: justify;
}

.modalButtonsContainer {
    padding: 14px;
    border-top: 1px solid #e9e9e9;
    background-color: #f3f3f3;
    border-radius: 0 0 15px 15px;
}

.closebtn {
    color: #999;
    float: right;
    cursor: pointer;
    font-size: 33px;
    margin-top: -12px;
    margin-left: 1px;
    margin-right: 11px;
}

.closebtn:hover {
    color: #000;
    float: right;
    cursor: pointer;
    font-size: 33px;
    margin-top: -12px;
    margin-left: 1px;
    margin-right: 11px;
}


button {
    outline: none !important;
}

@media screen and (max-width: 900px ) {
    .formFilling .infoBlock {
        text-align: left !important;
    }
    .nextButton {
        margin-top: 15px;
    }
    .formFinal {
        padding: 30px 0;
    }
    .formFinal .compLogo {
        margin-bottom: 20px;
    }
    .formFinal div.info h1 {
        font-size: 32px;
    }
    .formFinal div.info div {
        font-size: 14px;
        font-weight: 400;
        padding: 10px 0;
    }
}


@media screen and (max-width: 768px ) {
    h2 {
        font-size: 1.1rem;
    }

    .blockPage {
        width: 90% !important;
        left: 5% !important;
        top: 5% !important;
    }

    .modalMessagesContainer {
        height: 320px;
    }
}

@media screen and (max-width: 570px ) {
    .formFinal .compLogo img {
        max-width: 100%;
    }
    .formFinal div.info h1 {
        font-size: 22px;
        line-height: 26px;
    }
    .formFinal div.info div {
        font-size: 13px;
        line-height: 18px;
        padding: 5px 0;
    }
}

@media screen and (max-width: 480px ) {
    .infoBlock,
    .customerGroup,
    .filesBlock,
    .inputfile+label {
        min-width: auto !important;
    }

    .filesBlock {
        width: auto !important;
    }

    .form-control{
        max-width: 100% !important;
    }

    .inputCustomerGroup {
        width: initial !important;
    }
}

.arca-agreement .line-list {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
}

.arca-agreement .line-list li {
    padding-left: 1em;
    text-indent: -1em;
}

.arca-agreement .line-list li:before {
    content: "-";
    padding-right: 5px;
}



