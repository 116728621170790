body {
  margin: 0;
  padding: 0;
  font-family: MontserratARM;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: MontserratARM,
  monospace;
}

.bolder {
  font-weight: 600;
  font-size: 18px;
}
.thin {
  font-weight: 300;
  font-size: 18px;
  padding: 20px 0;
}
.formSuggestion {
  padding: 10px;
}