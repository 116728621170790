.inputBorrowersGroup {
    background-color: #fff;
    display: block;
    margin: 10px 0;
    position: relative;
    box-sizing: border-box;
    border-radius: 15px;
  }
  .inputBorrowersGroup label {
    padding: 12px 0px 12px 40px;
    width: 100%;
    display: block;
    text-align: left;
    color: #3C454C;
    cursor: pointer;
    position: relative;
    z-index: 2;
    transition: color 200ms ease-in;
    overflow: hidden;
    border-radius: 18px;
    border: 1px solid #D1D7DC;
    font-size: 12px;
    font-weight: 300;
  }
  .inputBorrowersGroup label:before {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    content: '';
    background-color: #36c265;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) scale3d(1, 1, 1);
            transform: translate(-50%, -50%) scale3d(1, 1, 1);
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
    z-index: -1;
  }
  /*.inputBorrowersGroup label:after {*/
  /*  width: 24px;*/
  /*  height: 24px;*/
  /*  content: '';*/
  /*  border: 1px solid #D1D7DC;*/
  /*  background-color: #fff;*/
  /*  background-position: -1px 0px;*/
  /*  border-radius: 50%;*/
  /*  z-index: 2;*/
  /*  position: absolute;*/
  /*  left: 9px;*/
  /*  top: 50%;*/
  /*  -webkit-transform: translateY(-50%);*/
  /*          transform: translateY(-50%);*/
  /*  cursor: pointer;*/
  /*  transition: all 200ms ease-in;*/
  /*}*/
  .inputBorrowersGroup input:checked ~ label {
    color: #fff;
    box-shadow: 2px 3px 12px #58d780;
    padding: 13px 0px 13px 40px;
    /* border: 2px solid #36c265; */
  }
  .inputBorrowersGroup input:checked ~ label:before {
    -webkit-transform: translate(-50%, -50%) scale3d(56, 56, 1);
            transform: translate(-50%, -50%) scale3d(56, 56, 1);
    opacity: 1;
  }
  /*.inputBorrowersGroup input:checked ~ label:after {*/
  /*  background-color: #fff;*/
  /*  border-color: #fff;;*/
  /*  box-shadow: 2px 3px 5px #357449;*/
  /*  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%2336c265' fill-rule='nonzero'/%3E%3C/svg%3E ");*/
  /*  background-repeat: no-repeat;*/
  /*}*/
  .inputBorrowersGroup input {
    width: 24px;
    height: 24px;
    order: 1;
    z-index: 2;
    position: absolute;
    left: 30px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    cursor: pointer;
    visibility: hidden;
  }
